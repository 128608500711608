import { Suspense, lazy } from 'react';

import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './protected';
import {
  authRoutes,
  RouteRenderer,
  publicRoutes,
  adminRoutes,
  errorRoutes,
  superAdmin,
  invoiceRoutes,
  employeeRouting,
} from './routes';
import Loader from '../components/layout/loader/loader';
import AdminLayout from '../layouts/admin';
import DefaultLayout from '../layouts/default';
import InvoiceLayout from '../layouts/invoice';
import { loaderSelector } from '../store/selectors/loader';

const PdfWorkOrder = lazy(() => import('../pages/public/PDFs/work-order-PDF'));
const PdfInvoices = lazy(() => import('../pages/public/PDFs/invoice-recipe-PDF'));

const MainRoutes = () => {
  const isloading = useSelector(loaderSelector);

  return (
    <Suspense fallback={<Loader isLoad={true} />}>
      <BrowserRouter>
        <Loader isLoad={isloading} />
        <Routes>
          <Route
            path="admin"
            element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="dashboard" replace />} />
            {adminRoutes.map(RouteRenderer)}
          </Route>
          <Route
            path="employee"
            element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="dashboard" replace />} />
            {employeeRouting.map(RouteRenderer)}
          </Route>
          <Route
            path="super-admin"
            element={
              <ProtectedRoute userRole="SuperAdmin">
                <AdminLayout />
              </ProtectedRoute>
            }
          >
            {superAdmin.map(RouteRenderer)}
          </Route>
          <Route
            path="invoice"
            element={
              <ProtectedRoute>
                <InvoiceLayout />
              </ProtectedRoute>
            }
          >
            {invoiceRoutes.map(RouteRenderer)}
          </Route>
          <Route element={<DefaultLayout />}>
            {publicRoutes.map(RouteRenderer)}
            {authRoutes.map(RouteRenderer)}
            {errorRoutes.map(RouteRenderer)}
          </Route>
          <Route key={'Work Order PDF'} path="/work-order-pdf/:id" element={<PdfWorkOrder />} />
          <Route key={'Invoice Recipe PDF'} path="/invoice-receipt-pdf/:id" element={<PdfInvoices />} />
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default MainRoutes;
