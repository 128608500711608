import { type IAction, type Nullable } from '../../@types';
import { SET_COMPANY_DATA, SET_CUSTOMER, SET_PAYMENT_METHOD } from '../types/invoice';

export interface IInvoiceStore {
  costumer: any;
  companyData: Nullable<Record<string, string>>;
  paymentMethod: string;
}

const initialState: IInvoiceStore = {
  costumer: '',
  companyData: null,
  paymentMethod: '',
};

const invoiceReducer = (state: IInvoiceStore = initialState, { type, payload }: IAction<any>): IInvoiceStore => {
  switch (type) {
    case SET_CUSTOMER: {
      return {
        ...state,
        costumer: payload,
      };
    }
    case SET_COMPANY_DATA: {
      return {
        ...state,
        companyData: payload,
      };
    }
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: payload,
      };
    }
    default:
      return state;
  }
};

export default invoiceReducer;
