import { type IAction, type Nullable } from '../../@types';
import { USER_PHONE_OR_EMAIL } from '../types/chatBot';

export interface IChatBot {
  userPhoneOrEmail: any;
}

const initialState: IChatBot = {
  userPhoneOrEmail: null,
};

const chatBotReducer = (state = initialState, { type, payload }: IAction<any>): any => {
  switch (type) {
    case USER_PHONE_OR_EMAIL: {
      return { userPhoneOrEmail: payload };
    }
    default:
      return state;
  }
};

export default chatBotReducer;
