import { useMemo } from 'react';

import NavigationRow from './row';
import useUser from '../../../../hooks/use-user';
import { type Icon } from '../../../shared/svg-icon';

export interface INavigationList {
  title: string;
  icon: Icon;
  path: string;
  visible: boolean;
  onClick?: () => void;
}

const Navigation = () => {
  const user: any = useUser();

  const adminNavigation = !!user.roles.find((role: string) => role === 'Admin');
  const superAdminNavigation = !!user.roles.find((role: string) => role === 'SuperAdmin');
  const employeeNavigation = !!user.roles.find((role: string) => role === 'Employee');

  const navigationList: INavigationList[] = useMemo(
    () => [
      {
        title: 'Dashboard',
        icon: 'dashboard',
        path: '/admin/dashboard',
        visible: adminNavigation,
      },
      {
        title: 'Dashboard',
        icon: 'dashboard',
        path: '/employee/dashboard',
        visible: employeeNavigation,
      },
      {
        title: 'Superadmin',
        icon: 'user',
        path: '/super-admin',
        visible: superAdminNavigation,
      },
      { title: 'Work Orders', icon: 'order', path: '/admin/work-orders', visible: adminNavigation },
      { title: 'Invoices', icon: 'invoice', path: '/admin/invoices', visible: adminNavigation },
      { title: 'Quickpay', icon: 'receipt', path: '/admin/instant-payout', visible: adminNavigation },
      { title: 'Payments', icon: 'Payments', path: '/admin/deposits', visible: adminNavigation },
    ],
    [],
  );

  return (
    <div className="flex flex-col gap-[10px] overflow-auto">
      {navigationList.map(row => (row.visible ? <NavigationRow key={row.path} {...row} /> : null))}
    </div>
  );
};

export default Navigation;
