import loaderImg from '../../../assets/images/loading.gif';

interface ILoader {
  isLoad: boolean;
  isAbsolute?: boolean;
}

const Loader = ({ isLoad }: ILoader) => {
  return isLoad ? (
    <div className="fixed top-0 left-0 h-screen w-screen z-[10000] flex justify-center items-center bg-black opacity-60">
      <img src={loaderImg} alt="loading" width={80} height={80} />
    </div>
  ) : (
    <></>
  );
};

export default Loader;
