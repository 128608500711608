import { type IRoutingCard } from '../../components/admin/routing-cards';

export const EARTH_RADIUS_MI = 3958.8;

export const superAdminRoutingCards: IRoutingCard[] = [
  {
    icon: 'home',
    title: 'VENDOR LISTING',
    description: 'View and pay Vendors’ invoices',
    descriptionColor: 'text-grey-500',
    path: '/super-admin/vendor-listing',
  },
  {
    icon: 'text',
    title: 'VENDOR INVOICES',
    description: 'View and edit Vendors’ list',
    descriptionColor: 'text-grey-500',
    path: '/super-admin/vendor-invoices',
  },
  {
    icon: 'services',
    title: 'ALL SERVICES',
    description: 'View and edit services’ list',
    descriptionColor: 'text-grey-500',
    path: '/super-admin/services',
  },
];

export const adminRoutingCards: IRoutingCard[] = [
  {
    icon: 'company-information',
    title: 'COMPANY INFORMATION',
    description: "View your company's information",
    path: '/admin/dashboard/company',
  },
  {
    icon: 'customers-account',
    title: 'CUSTOMERS',
    description: 'Add or remove your repeat customers for fast checkout',
    path: '/admin/dashboard/carriers',
  },
  {
    icon: 'user-accounts',
    title: 'USER PROFILES',
    description: 'Add or Edit your user profiles to assign roles.',
    path: '/admin/dashboard/user-accounts',
  },
  {
    icon: 'work-order-templates',
    title: 'WORK ORDER TEMPLATES',
    description: 'Create or edit work authorization forms',
    path: '/admin/dashboard/work-order-templates',
  },
  {
    icon: 'custom-fields',
    title: 'INVOICES INFORMATION',
    description: 'Create and edit information on your invoices',
    path: '/admin/dashboard/invoices/custom-fields',
  },
  {
    icon: 'invoices-products',
    title: 'INVOICE: PARTS AND LABOR',
    description: 'Add or remove your parts and services for quick invoicing',
    path: '/admin/dashboard/invoices/products',
  },
  {
    icon: 'invoices-product-taxes',
    title: 'TAX SETTINGS',
    description: 'Setup taxes for parts and services',
    path: '/admin/dashboard/invoices/products-taxes',
  },
];

export const employeeRoutingCards: IRoutingCard[] = [
  {
    icon: 'work-orders',
    title: 'WORK ORDERS',
    description: 'Create or manage the Work Orders',
    path: '/employee/dashboard/work-orders',
  },
  {
    icon: 'invoices',
    title: 'INVOICES',
    description: 'Create or manage the Invoices',
    path: '/employee/dashboard/invoices',
  },
  {
    icon: 'deposits',
    title: 'ALL TRANSACTIONS ',
    description: 'View and download all payouts',
    path: `/employee/dashboard/deposits`,
  },
];

export const getSuperAdminRoutingCards = (companyId: string) => [
  {
    icon: 'company-information',
    title: 'COMPANY INFORMATION',
    description: 'View and edit company information',
    path: `/super-admin/dashboard/${companyId}/company`,
  },
  {
    icon: 'customers-account',
    title: 'CUSTOMERS',
    description: 'Add or remove repeat customers',
    path: `/super-admin/dashboard/${companyId}/carriers`,
  },
  {
    icon: 'user-accounts',
    title: 'USER PROFILES',
    description: 'Add or edit user profiles',
    path: `/super-admin/dashboard/${companyId}/user-accounts`,
  },
  {
    icon: 'instant-payout',
    title: 'QUICKPAY',
    description: 'Get paid instantly with a direct deposit ',
    path: `/super-admin/dashboard/${companyId}/instant-payout`,
  },
  {
    icon: 'work-order-templates',
    title: 'WORK ORDER TEMPLATES',
    description: 'Create or edit work authorization forms',
    path: `/super-admin/dashboard/${companyId}/work-order-templates`,
  },
  {
    icon: 'custom-fields',
    title: 'INVOICES INFORMATION',
    description: 'Create and edit invoice information',
    path: `/super-admin/dashboard/${companyId}/invoices/custom-fields`,
  },
  {
    icon: 'invoices-products',
    title: 'INVOICE: PARTS AND LABOR',
    description: 'Add or remove parts and services',
    path: `/super-admin/dashboard/${companyId}/invoices/products`,
  },
  {
    icon: 'invoices-product-taxes',
    title: 'TAX SETTINGS',
    description: 'Setup taxes for parts and services',
    path: `/super-admin/dashboard/${companyId}/invoices/products-taxes`,
  },
  {
    icon: 'work-orders',
    title: 'WORK ORDERS',
    description: 'Create or manage the Work Orders',
    path: `/super-admin/dashboard/${companyId}/work-orders`,
  },
  {
    icon: 'invoices',
    title: 'INVOICES',
    description: 'Create or manage the Invoices',
    path: `/super-admin/dashboard/${companyId}/invoices`,
  },
  {
    icon: 'deposits',
    title: 'ALL TRANSACTIONS',
    description: 'View and download all payouts',
    path: `/super-admin/dashboard/${companyId}/deposits`,
  },
];

export const partLaborOptions = [
  { label: 'Part', value: 'Part' },
  { label: 'Labor', value: 'Labor' },
];

export const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};
