import { useSelector } from 'react-redux';

import NavigationMobile from './navigation-mobile';
import { sidebarVisibleSelector } from '../../../../store/selectors/header';

const MobileSidebar = ({ className }: any) => {
  const sidebarVisible = useSelector(sidebarVisibleSelector);

  const visibilityClasses = sidebarVisible ? 'w-full px-[20px]' : 'w-0 p-0';

  return (
    <div className={`${visibilityClasses} overflow-auto mobile:overflow-hidden bg-[#131313] ${className}`}>
      {sidebarVisible && <NavigationMobile />}
    </div>
  );
};

export default MobileSidebar;
