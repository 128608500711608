import { type ButtonHTMLAttributes, type FC, memo } from 'react';

import { Link } from 'react-router-dom';

import SvgIcon, { type Icon } from '../../svg-icon';

const sizes: Readonly<Record<string, string>> = {
  'primary-lg': 'w-fit xl:h-[60px] medium:h-[40px] h-[40px] medium:text-[16px] xl:py-2.5 px-6',
  'admin-primary-lg': 'w-fit px-[15px] medium:px-[16px] py-[10px] medium:py-[8px] medium:text-[16px] medium:h-[40px]',
  'secondary-fit': 'w-fit h-[60px] py-2.5 px-[15px] medium:h-[40px] text-[24px]',
  'admin-secondary-fit': 'w-fit h-[50px] medium:h-[40px] medium:text-[16px] py-2.5 px-[15px]',
  'secondary-full': 'w-full xl:h-[60px] h-[40px] py-2.5 px-[15px] medium:text-[16px] medium:h-[40px]',
  'text-lg': 'w-fit h-[60px] py-2.5 px-0',
  icon: 'w-fit h-[30px]',
  button: 'w-fit px-[24px] medium:px-[16px] py-[10px] medium:py-[8px] medium:h-[40px]',
  'chat-bot':
    'w-[65px] mobile:w-[45px] h-[65px] mobile:h-[45px] fixed bottom-8 right-8 rounded-full flex justify-center mobile:bottom-[70px] mobile:right-[20px]',
  'send-email': 'font-normal w-full h-[50px] text-[20px] font-medium',
  'send-download': 'w-full h-[50px] medium:h-[40px] text-[20px] medium:text-[16px] font-medium',
  'home-sign-in':
    'w-fit h-[60px] medium:h-[40px] text-[24px] medium:text-[18px] px-[24px] py-[10px] text-center leading-normal font-bold',
  'home-register':
    'w-fit h-[60px] medium:h-[40px] text-[24px] medium:text-[18px] px-[15px] py-[10px] text-center leading-normal font-bold',
  'home-search': 'w-full h-[60px] medium:h-[40px] text-[24px] medium:!text-[18px] px-[15px] py-[10px] text-center font-bold',
  'home-get-app': 'w-full h-[60px] medium:h-[40px] text-[20px] px-[24px] py-[10px] text-center font-bold',
  'services-near':
    'w-fit text-[20px] medium:text-[16px] medium:px-[10px] medium:py-[8px] px-[15px] py-[10px] text-center gap-[10px] font-medium',
  'add-size': 'h-[50px] medium:h-[40px] w-full text-[20px] font-medium px-[24px] py-[10px] medium:text-[16px] leading-normal ',
  'back-admin':
    'w-fit h-[50px] px-[15px] py-[10px] medium:px-[10px] medium:py-[8px] text-[20px] medium:text-[16px] medium:h-[40px] font-medium',
  none: '',
} as const;

export type Size = keyof typeof sizes;

const variants: Readonly<Record<string, string>> = {
  text: 'text-white hover:text-white-bh',
  'text-white': 'hover:bg-black-1',
  'text-black': 'text-black-1 hover:text-white-bh hover:stroke-white-bh',
  primary: 'bg-blue-500 text-white hover:bg-blue-600',
  'admin-primary': 'bg-blue-500 text-white hover:bg-blue-600',
  'admin-secondary': 'min-w-[150px] medium:min-w-[100px] h-[50px] bg-white-2 text-grey-500 hover:bg-grey-100',
  'admin-back': 'bg-white-2 text-grey-500 hover:bg-grey-100',
  secondary: 'bg-grey-700 text-white hover:bg-black-1',
} as const;

export type Variant = keyof typeof variants;

interface IButton extends ButtonHTMLAttributes<any> {
  icon?: Icon;
  iconClassName?: string;
  className?: string;
  size?: Size;
  variant?: Variant;
  label?: string;
  link?: boolean;
  iconRight?: boolean;
  target?: string;
  path?: string;
}

const Button: FC<IButton> = ({
  label,
  size = 'primary-lg',
  variant = 'primary',
  link = false,
  target = '',
  path,
  icon,
  iconRight,
  iconClassName,
  className = '',
  ...rest
}) => {
  if (link && path) {
    return (
      <Link to={path} target={target}>
        <button
          className={`flex justify-center gap-2.5 medium:gap-[3px] items-center rounded-[10px] ${className} ${sizes[size]} ${variants[variant]}`}
          {...rest}
        >
          {icon && <SvgIcon icon={icon} className={iconClassName} />}
          {label}
        </button>
      </Link>
    );
  }
  if (iconRight) {
    return (
      <button
        className={`flex px-[15px] py-[10px] justify-between gap-[5px] medium:gap-[3px] items-center rounded-[10px] text-[20px] w-full medium:text-[16px] font-medium ${className}`}
        {...rest}
      >
        {label}
        {icon && <SvgIcon icon={icon} className={iconClassName} />}
      </button>
    );
  }

  return (
    <button
      className={`flex justify-center gap-[5px] medium:gap-[3px] items-center rounded-[10px] ${sizes[size]} ${className} ${variants[variant]}`}
      {...rest}
    >
      {icon && <SvgIcon icon={icon} className={iconClassName} />}
      {label}
    </button>
  );
};

export default memo(Button);
