import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ChatBotMessage from './chat-bot-message';
import Button from '../../components/shared/input-sources/button';
import { chatOpen } from '../../store/actions/header';
import { visibleChat } from '../../store/selectors/header';

const ChatBot = () => {
  const dispatch = useDispatch();
  const visibleChats = useSelector(visibleChat);

  return (
    <div>
      <div className="w-full h-full">
        <Button
          icon={visibleChats ? 'chevron-down' : 'chat-massage'}
          size="chat-bot"
          iconClassName={`stroke-white ${
            visibleChats ? 'w-[24px] h-[24px]' : 'w-[40px] mobile:w-[30px] mobile:h-[30px] h-[40px]'
          } !z-50`}
          onClick={() => dispatch(chatOpen(!visibleChats))}
        />
      </div>
      {visibleChats && (
        <div>
          <ChatBotMessage handleClose={() => dispatch(chatOpen(false))} />
        </div>
      )}
    </div>
  );
};

export default ChatBot;
