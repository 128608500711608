import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

import NewPageTitle from '../components/layout/admin/new-page-title';
import Container from '../components/layout/container';
import AdminHeader from '../components/layout/header/admin';
import Sidebar from '../components/layout/sidebar';
import MobileSidebar from '../components/layout/sidebar/mobile';
import invoiceService from '../core/api/invoice-service';
import useUser from '../hooks/use-user';
import ChatBot from '../pages/chat-bot';
import { setCompanyInfo } from '../store/actions/invoice';
import { companyDataSelector } from '../store/selectors/invoice';

const InvoiceLayout = () => {
  const { companyId, id } = useParams();
  const user = useUser();

  const dispatch = useDispatch();

  const invoice = useSelector(companyDataSelector);

  useEffect(() => {
    if (!invoice) fetchInvoice();
  }, [id]);

  const fetchInvoice = async () => {
    const data = await invoiceService.getInvoice(user?.company?.id || companyId, `${id}`);
    dispatch(setCompanyInfo(data));
  };

  return (
    <Container>
      <AdminHeader />
      <div className="w-full h-full flex flex-row items-start mobile:overflow-auto mobile:mt-[90px] mobile:h-[calc(100vh_-_180px)]">
        <Sidebar className="mobile:hidden" />
        <div className="relative h-[calc(100vh_-_90px)] w-full overflow-x-auto overflow-y-scroll p-[30px] medium:p-[20px] bg-[#F2F2F2] mobile:h-[calc(100vh_-_180px)] mobile:py-[30px] mobile:px-[20px]">
          <NewPageTitle />
          <Outlet />
          <ChatBot />
        </div>
      </div>
      <MobileSidebar className="hidden mobile:flex mobile:h-[90px]" />
    </Container>
  );
};

export default InvoiceLayout;
