import { type IAction } from '../../@types';
import { SET_COMPANY } from '../types/company';

export interface ICompanyStore {
  company: any;
}

const initialState: ICompanyStore = {
  company: null,
};

const companyReducer = (state: ICompanyStore = initialState, { type, payload }: IAction<any>): ICompanyStore => {
  switch (type) {
    case SET_COMPANY: {
      return {
        ...state,
        company: payload,
      };
    }
    default:
      return state;
  }
};

export default companyReducer;
