import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducer from './reducers';

const persistConfig = {
  key: 'header',
  storage,
};

const setMiddleware = () => {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return composeEnhancers(applyMiddleware());
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, setMiddleware());
export const persistor = persistStore(store);
