import axiosClient from '../';
import { formDataConfig } from '../../../utils/constants';
import { objectToFormData } from '../../../utils/helpers';

class InvoicesService {
  private readonly path: string;

  constructor() {
    this.path = '/invoices';
  }

  public async getSearchInvoice(companyId: string | undefined, searchKey: string) {
    try {
      const requestPath = companyId
        ? `${this.path}/search/?companyId=${companyId}&searchKey=${searchKey}`
        : `${this.path}/search/?searchKey=${searchKey}`;
      const { data } = await axiosClient.get(requestPath);

      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async getSearchInvoiceVendors(searchKey: string) {
    try {
      const requestPath = `${this.path}/vendors/?searchKey=${searchKey}`;
      const { data } = await axiosClient.get(requestPath);

      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async getInvoice(companyId: any, id?: string) {
    try {
      const requestPath = companyId ? `${this.path}${id ? `/${id}` : ''}?id=${companyId}` : this.path;
      const { data } = await axiosClient.get(requestPath);

      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async getRemoteInvoice(id: string) {
    try {
      const requestPath = `${this.path}/remote-checkout/${id}`;
      const { data } = await axiosClient.get(requestPath);

      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async createInvoice(companyId: string) {
    try {
      const requestPath = companyId ? `${this.path}?id=${companyId}` : this.path;

      const { data } = await axiosClient.post(requestPath);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async updateDepositStatus(invoiceId: string, status: boolean, notifyVendor: boolean) {
    try {
      const requestPath = `${this.path}/vendorsUpdate/${invoiceId}`;

      const { data } = await axiosClient.post(requestPath, { depositStatus: status, notifyVendor: notifyVendor });
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async remoteCheckout(invoiceId: string, emailOrPhone: string) {
    try {
      const requestPath = `/remote-checkout/${invoiceId}`;

      const { data } = await axiosClient.post(requestPath, { emailOrPhone: emailOrPhone });
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async invoicesCash(invoiceId: string, emailOrPhone: string) {
    try {
      const requestPath = `${this.path}/cash-payment/${invoiceId}`;

      const { data } = await axiosClient.post(requestPath, { emailOrPhone: emailOrPhone });
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async checkLineItem(invoiceId: string) {
    try {
      const requestPath = `${this.path}/check-line-item/${invoiceId}`;

      const { data } = await axiosClient.get(requestPath);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async sendReceipt(invoiceId: string, emailOrPhone: string) {
    try {
      const requestPath = `${this.path}/send-receipt/${invoiceId}`;

      const { data } = await axiosClient.post(requestPath, { emailOrPhone: emailOrPhone });
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async createInvoiceBasedOnWorkOrder(companyId: string, workOrderId: number) {
    try {
      const requestPath = companyId ? `${this.path}?id=${companyId}` : this.path;
      const body = { workOrderId: workOrderId };

      const { data } = await axiosClient.post(requestPath, body);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async updateInvoice(id: string, body: any, field?: string) {
    try {
      const requestPath = id ? `${this.path}/${id}` : this.path;

      if (field) {
        body = {
          [field]: {
            ...body,
          },
        };
      }

      const { data } = await axiosClient.patch(requestPath, body);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async updateAttachments(id: string, body: any) {
    try {
      const requestPath = id ? `${this.path}/${id}` : this.path;

      body = objectToFormData(body);

      const { data } = await axiosClient.patch(requestPath, body, formDataConfig);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async getInvoicePDF(invoiceId: number) {
    try {
      const { data } = await axiosClient.get(`${this.path}/pdf/${invoiceId}`);

      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async deleteInvoice(invoiceId: number) {
    try {
      const { data } = await axiosClient.delete(`${this.path}/${invoiceId}`);

      return data;
    } catch (e) {
      console.error(e);
    }
  }
}

export default new InvoicesService();
