import { combineReducers } from 'redux';

import authReduser, { type IAuthStore } from './auth';
import chatBotReducer, { type IChatBot } from './chatBot';
import companyReducer, { type ICompanyStore } from './company';
import headerReducer, { type IHeaderStore } from './header';
import invoiceReducer, { type IInvoiceStore } from './invoice';
import loaderReducer, { type ILoaderStore } from './loader';

export interface ICombinedState {
  auth: IAuthStore;
  header: IHeaderStore;
  invoice: IInvoiceStore;
  company: ICompanyStore;
  chatBot: IChatBot;
  loader: ILoaderStore;
}

export default combineReducers({
  auth: authReduser,
  header: headerReducer,
  invoice: invoiceReducer,
  company: companyReducer,
  loader: loaderReducer,
  chatBot: chatBotReducer,
});
