import { type IAction } from '../../@types';
import { CHAT_BOT, MOBILE_TOGGLE_SIDEBAR, TOGGLE_SIDEBAR } from '../types/header';

export interface IHeaderStore {
  sidebarVisible: boolean;
  sidebarVisibleMobile: boolean;
  visibleChat: boolean;
}

const initialState: IHeaderStore = {
  sidebarVisible: true,
  sidebarVisibleMobile: true,
  visibleChat: false,
};

const headerReducer = (state: IHeaderStore = initialState, { type, payload }: IAction<any>): IHeaderStore => {
  switch (type) {
    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarVisible: !state.sidebarVisible,
      };
    }
    case MOBILE_TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarVisibleMobile: !state.sidebarVisibleMobile,
      };
    }
    case CHAT_BOT: {
      return {
        ...state,
        visibleChat: payload,
      };
    }
    default:
      return state;
  }
};

export default headerReducer;
