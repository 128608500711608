import { memo, useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as io from 'socket.io-client';

import MailOrPhoneMessage from './mail-or-phone-message';
import MyMassage from './my-message';
import YourMassage from './your-massage';
import Button from '../../../components/shared/input-sources/button';
import Input from '../../../components/shared/input-sources/input';
import SvgIcon from '../../../components/shared/svg-icon';
import Typography from '../../../components/shared/typography';
import chatService from '../../../core/api/chat-service';
import { setChatBotPhoneOrEmail } from '../../../store/actions/chatBot';
import { setLoader } from '../../../store/actions/loader';
import { ChatBotSidebar } from '../../../store/selectors/chatBot';

const socket: io.Socket = io.connect(`${process.env.REACT_APP_API_SOCKET_URL}/chat`);

const ChatMessage = ({ handleClose }: any) => {
  const [inputMassage, setInputMessage] = useState('');
  const [messages, setMessages] = useState<any>([]);
  const [confirm, setConfirm] = useState<any>();
  const dispatch = useDispatch();
  const [next, setNext] = useState('');
  const [formData, setFormData] = useState<any>();
  const emailOrPhone = useSelector(ChatBotSidebar);
  const [verificationStatus, setVerificationStatus] = useState<any>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const isInputNotEmpty = /^\s*$/.test(inputMassage) === false;

  useEffect(() => {
    getChatBot();
  }, [next]);

  useEffect(() => {
    getMessage();
  }, [socket, inputMassage]);

  useEffect(() => {
    scrollToBottom();
    getChatBot();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (formData) {
      socket.on('emailPhoneCompanyName', (e: any) => {
        setNext(e);
      });
    }
  }, [socket, formData]);

  useEffect(() => {
    if (next === 'awaiting') {
      setConfirm(formData);
    }
  }, [next]);

  useEffect(() => {
    if (next === 'awaiting' || next === 'Verify failed') {
      if (verificationStatus) {
        socket.emit('confirm', { confirm: verificationStatus, mailOrPhone: formData.phoneOrEmail });
      }
    }
  }, [verificationStatus]);

  useEffect(() => {
    if (verificationStatus) {
      socket.on('confirm', (e: any) => {
        if (e === 'verified') {
          dispatch(setChatBotPhoneOrEmail(confirm.phoneOrEmail));
        }
        setNext(e);
      });
      setVerificationStatus(null);
    }
  }, [socket, verificationStatus]);

  const emailSubmit = (data: any) => {
    if (confirm) {
      if (data) {
        setVerificationStatus(data);
      }
    } else {
      socket.emit('emailPhoneCompanyName', {
        emailOrPhone: data.phoneOrEmail,
        companyName: data.companyName || '',
        role: 1,
        date: moment().format('h:mm a'),
      });
      setFormData(data);
    }
  };

  const getChatBot = async () => {
    if (emailOrPhone) {
      const data = await chatService.getChatService(emailOrPhone);
      if (data) {
        setMessages(Object.values(JSON.parse(data)));
      }
    }
  };

  const getMessage = () => {
    socket.on('chatHistory', (e: any) => {
      setMessages(Object.values(JSON.parse(e)));
    });
  };

  const scrollToBottom = () => {
    bottomRef.current?.scrollTo({ top: bottomRef.current.scrollHeight, behavior: 'smooth' });
  };
  const dateObject = new Date();
  const minutes = ('0' + dateObject.getMinutes()).slice(-2);
  const hours = ('0' + dateObject.getHours()).slice(-2);
  const formattedDate = hours + ':' + minutes;

  const onSend = () => {
    if (isInputNotEmpty) {
      socket.emit('message', {
        mail: emailOrPhone,
        role: 0,
        date: formattedDate,
        content: inputMassage,
      });
    }
    setInputMessage('');
  };

  const chatDelete = async () => {
    if (emailOrPhone) {
      dispatch(setLoader(true));
      const response = await chatService.deleteChat(emailOrPhone);
      dispatch(setLoader(false));
      if (response) {
        dispatch(setChatBotPhoneOrEmail(null));
        handleClose();
      }
    }
  };

  return (
    <div className="h-[calc(100vh_-_150px)] medium:h-[calc(100vh_-_200px)] w-[450px] mobile:w-full mobile:!h-full bg-white fixed bottom-28 right-8 mobile:bottom-0 mobile:right-0 rounded-[30px] mobile:!rounded-[0px] z-50 shadow-2xl">
      <div className="flex h-full flex-col rounded-[30px] mobile:!rounded-[0px]">
        <div className="w-full rounded-t-[30px] mobile:!rounded-[0px]">
          <div
            className={`bg-blue-500 w-full h-[100px] rounded-t-[30px] flex shadow-2xl ${
              emailOrPhone ? 'justify-between' : 'justify-center mobile:justify-between '
            } items-center shadow-2xl mobile:!rounded-[0px] mobile:px-[20px] mobile:py-[10px] mobile:!h-fit p-[15px]`}
          >
            <SvgIcon icon="logo" className="w-[150px] h-[50px] fill-white" />{' '}
            {emailOrPhone ? (
              <>
                <div className="mobile:flex items-center hidden gap-[5px]">
                  <div className="w-[40px] h-[40px] flex justify-center items-center">
                    <Button
                      icon="chevron-down"
                      variant=""
                      size={'none'}
                      iconClassName="stroke-white w-[24px] h-[24px] p-[5px]"
                      className="w-[24px] h-[24px]"
                      onClick={handleClose}
                    />
                  </div>
                  <div className="w-[40px] h-[40px] flex justify-center items-center">
                    <Button
                      icon="log-out"
                      variant=""
                      size={'none'}
                      iconClassName="stroke-white w-[20px] h-[20px]"
                      className="w-[24px] h-[24px]"
                      onClick={chatDelete}
                    />
                  </div>
                </div>

                <div className="flex items-center mobile:hidden">
                  <Button
                    iconRight
                    icon="log-out"
                    iconClassName="stroke-white w-[24px] h-[24px]"
                    label="End Chat"
                    className="!h-[40px] !text-[18px] text-white leading-[27px] font-medium not-italic flex justify-center !gap-[10px] hover:bg-white hover:bg-opacity-[20%] "
                    onClick={chatDelete}
                  />
                </div>
              </>
            ) : (
              <>
                <Button
                  icon="chevron-down"
                  variant=""
                  size={'none'}
                  iconClassName="stroke-white w-[24px] h-[24px] p-[5px]"
                  className="w-[24px] h-[24px] hidden mobile:flex"
                  onClick={handleClose}
                />
              </>
            )}
          </div>
        </div>
        <div
          className="flex flex-col h-full bg-white-2 mobile:bg-white mobile:h-full gap-[10px] p-[10px] overflow-y-auto"
          ref={emailOrPhone && bottomRef}
        >
          {emailOrPhone ? (
            <div className="pb-[10px]">
              <div className="flex h-full gap-[10px]">
                <div className="flex items-start">
                  <div className="w-[40px] h-[40px] bg-white flex justify-center items-center rounded-full border border-slate-600 border-opacity-30">
                    <SvgIcon icon="user" className="stroke-blue-500 w-[20px] h-[24px]" />
                  </div>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <div className="flex h-fit justify-start gap-[10px]">
                    <div className="bg-white px-[15px] py-[10px] rounded-[15px] max-w-[300px] border border-slate-600 border-opacity-30">
                      <Typography tag="span" variant="chat-message" className="break-words text-gray-500">
                        Hey there! 👋🏻
                      </Typography>
                    </div>
                    <div className="flex items-center">
                      <Typography tag="span" variant="data" className="text-gray-500 break-words">
                        {formattedDate}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex h-fit justify-start gap-[10px]">
                    <div className="bg-white px-[15px] py-[10px] rounded-[15px] max-w-[300px] border border-slate-600 border-opacity-30">
                      <Typography tag="span" variant="chat-message" className="text-gray-500  break-words">
                        Welcome to Roadifix Livechat. Please type down your question below 🥰
                      </Typography>
                    </div>
                    <div className="flex items-center">
                      <Typography tag="span" variant="data" className="text-gray-500 break-words">
                        {formattedDate}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {emailOrPhone ? (
            messages.map((message: any, index: number) => {
              if (message?.role) {
                return <MyMassage message={message} key={index} />;
              } else {
                return <YourMassage message={message} key={index} />;
              }
            })
          ) : (
            <MailOrPhoneMessage emailSubmit={emailSubmit} confirm={confirm} socket={socket} />
          )}
        </div>
        <div className="w-full h-fit flex flex-col gap-[10px] justify-end">
          {emailOrPhone && (
            <div className="w-full px-[10px] border-t-[1.5px] pt-[5px] border-slate-600 border-opacity-30 flex items-center">
              <Input
                className="w-full h-[74px] px-[10px] pr-[50px] focus:!border-none focus-visible:border-none outline-none text-[18px] leading-[27px] font-normal not-italic"
                icon="send"
                placeholder="Write a message"
                onClickIcon={onSend}
                iconColorClass="stroke-grey-500 !right-[10px] w-[30px] h-[30px]"
                value={inputMassage}
                onChange={event => setInputMessage(event.target.value)}
              />
            </div>
          )}
        </div>
        <div
          className={`w-full ${
            emailOrPhone ? 'border-t-[1.5px]' : ' mobile:border-t-[0px] border-t-[1.5px]'
          } border-slate-600 border-opacity-30 flex items-center h-[42px] justify-center`}
        >
          <Typography tag="span" variant="chat" className="text-gray-500 break-words">
            Powered by{' '}
            <Typography tag="span" variant="chat" className="text-gray-500 break-words !font-semibold">
              Roadifix
            </Typography>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default memo(ChatMessage);
