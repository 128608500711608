import { createElement, type FC, type HTMLAttributes, HTMLProps, memo, type ReactNode } from 'react';

type TextTag = 'p' | 'b' | 'strong' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'label' | 'div';

const variants: Readonly<Record<string, string>> = {
  'display-1': 'font-black text-[50px] leading-[55px] medium:text-[30px] medium:leading-[38px]',
  'display-2': 'font-light text-[42px] leading-[49px]',
  'display-3': 'font-black text-[35px] leading-[75px] medium:text-[30px] medium:leading-[58px]',
  header:
    'font-bold text-[24px] leading-[28px] mobile:leading-[18px] mobile:text-[14px] medium:text-[20px] medium:leading-[40px]',
  'sub-header': 'font-semibold medium:text-[18px] leading-[27px] medium:font-medium text-[28px] medium:leading-[32px]',
  button: 'font-bold text-[20px] medium:text-[18px] leading-[23px] medium:leading-[18px]',
  'invoice-create': 'text-[28px] not-italic font-medium leading-normal medium:text-[20px]',
  'nav-link': 'font-medium text-[18px] medium:text-[14px] leading-[23px] medium:leading-[13px]',
  'body-1': 'font-medium text-[16px] leading-[24px] medium:text-[14px] medium:leading-[30px]',
  'body-2': 'font-normal text-[14px] leading-[21px] medium:text-sm medium:leading-4',
  caption: 'font-normal text-[14px] leading-[16px] medium:text-xs medium:leading-[14px]',
  'card-title': 'font-bold text-[16px] leading-[18px]',
  'bank-card': 'items-start text-[34px] font-black text-white',
  'bank-name': 'items-center text-white font-semibold medium:text-[14px]',
  'bank-money':
    'items-end xl:text-[24px] medium:text-[18px] font-semibold xl:w-[58px] xl:h-[28px] medium:w-[43px] medium:h-[21px] text-white',
  'document-body': 'font-work-sans text-20 font-normal font-medium leading-150 text-justify',
  'document-titel': 'font-work-sans text-28 font-normal font-medium leading-normal',
  vender: '!text-[20px] medium:text-[14px] mobile:!text-[10px] font-work-sans text-base font-medium leading-1.5',
  'popup-header': 'font-sans text-[20px] medium:text-[18px] font-medium not-italic leading-normal',
  none: '',
  'pay-text': 'text-base font-medium leading-normal',
  data: 'font-normal text-[14px] leading-[30px] medium:text-sm medium:leading-4',
  refund: 'text-[20px] leading-[30px] medium:text-[14px] medium:leading-[26px] font-medium',
  expiration: 'font-sans text-[16px] not-italic font-medium leading-6',
  'display-4': 'font-light text-[42px] leading-[49px] font-medium not-italic font-sans text-center mobile:text-[32px]',
  check: 'text-[28px] not-italic leading-normal font-sans medium:text-[24px] mobile:!text-[18px]',
  'error-message-page': 'text-[34px] font-black medium:text-[28px] mobile:!text-[24px]',
  'chat-message': 'font-normal text-[20px] leading-[30px] medium:text-[16px] medium:leading-[24px] not-italic text-center',
  chat: 'font-normal text-[15px] leading-[22.5px]',
  'body-3': 'font-medium text-[20px] leading-[30px] medium:text-[14px] medium:leading-[21px] not-italic',
  'header-title': 'text-center text-neutral-900 text-[28px] font-medium font-[Work Sans] medium:text-xl',
  'deposit-amount': 'text-[40px] medium:text-[30px] not-italic font-black uppercase leading-normal',
  'deposit-header': 'text-[20px] medium:text-[16px] not-italic font-bold uppercase leading-normal font-[Work Sans]',
} as const;

export type Variant = keyof typeof variants;

interface ITypoProps extends HTMLAttributes<any> {
  tag: TextTag;
  variant: Variant;
  children?: ReactNode | string;
  className?: string;
  htmlFor?: string;
}

const Typography: FC<ITypoProps> = ({ tag, variant, children, className, ...rest }) => {
  if (tag === 'div') {
    // Render as a div with dangerouslySetInnerHTML when tag is 'div'
    return createElement(
      tag,
      {
        className: `${variants[variant]} ${className}`,
        ...rest,
        dangerouslySetInnerHTML: { __html: children as string },
      },
      null,
    );
  } else {
    // Render other tags as usual
    return createElement(
      tag,
      {
        className: `${variants[variant]} ${className}`,
        ...rest,
      },
      children,
    );
  }
};

export default memo(Typography);
