import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import NavigationRowMobile from './row-mobile';
import useUser from '../../../../../hooks/use-user';
import { RolesEnum } from '../../../../../utils/enums';
import { type Icon } from '../../../../shared/svg-icon';

export interface INavigationList {
  title: string;
  icon: Icon;
  path: string;
  visible: boolean;
}

const NavigationMobile = () => {
  const user: any = useUser();
  const { companyId } = useParams();

  const adminNavigation = !!user.roles.find((role: string) => role === RolesEnum.Admin);
  const employeeNavigation = !!user.roles.find((role: string) => role === RolesEnum.Employee);
  const superAdminNavigation = !!(user.roles[0] === RolesEnum.SuperAdmin && companyId);

  const navigationList: INavigationList[] = useMemo(
    () => [
      { title: 'Work Orders', icon: 'order', path: '/employee/dashboard/work-orders', visible: employeeNavigation },
      { title: 'Invoices', icon: 'invoice', path: '/employee/dashboard/invoices', visible: employeeNavigation },
      { title: 'Work Orders', icon: 'order', path: '/admin/work-orders', visible: adminNavigation },
      { title: 'Invoices', icon: 'invoice', path: '/admin/invoices', visible: adminNavigation },
      { title: 'Quickpay', icon: 'receipt', path: '/admin/instant-payout', visible: adminNavigation },
      {
        title: 'Work Orders',
        icon: 'order',
        path: `/super-admin/dashboard/${companyId}/work-orders`,
        visible: superAdminNavigation,
      },
      { title: 'Invoices', icon: 'invoice', path: `/super-admin/dashboard/${companyId}/invoices`, visible: superAdminNavigation },
      {
        title: 'Quickpay',
        icon: 'receipt',
        path: `/super-admin/dashboard/${companyId}/instant-payout`,
        visible: superAdminNavigation,
      },
    ],
    [location.pathname],
  );

  return (
    <div className="flex xl:flex-col gap-[10px] overflow-auto justify-between mobile:overflow-hidden mobile:h-full mobile:items-center mobile:w-full justify-between">
      {navigationList.map(row => (row.visible ? <NavigationRowMobile key={row.path} {...row} /> : null))}
    </div>
  );
};

export default NavigationMobile;
