import { memo, type ReactNode } from 'react';

interface IPopupWrapperProps {
  children?: ReactNode;
}

const PopupWrapper = ({ children }: IPopupWrapperProps) => {
  return (
    <div className={`w-full top-0 left-0 fixed h-full flex items-center justify-center bg-[rgba(0,0,0,0.5)] z-30`}>
      <div
        className={`mobile:!w-full mobile:!h-full gap-[20px] p-[20px] medium:h-fit medium:w-fit absolute rounded-[25px] mobile:rounded-[0px]  border bg-white border-white flex flex-col z-10`}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(PopupWrapper);
