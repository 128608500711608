import { type IAction } from '../../@types';
import { LOADER } from '../types/loader';

export interface ILoaderStore {
  isLoading: boolean;
}

const initialState: ILoaderStore = {
  isLoading: false,
};

const loaderReducer = (state: ILoaderStore = initialState, { type, payload }: IAction<any>): ILoaderStore => {
  switch (type) {
    case LOADER: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    default:
      return state;
  }
};

export default loaderReducer;
