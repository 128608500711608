import { type IAction } from '../../@types';
import { SET_CURRENT_USER, SIGN_IN } from '../types/auth';

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  company: any;
  roles: string[];
}

interface ISignInPayload {
  token: string;
  rememberCheck: boolean;
  user: IUser;
}

export const signIn = (payload: ISignInPayload): IAction<ISignInPayload> => ({
  type: SIGN_IN,
  payload,
});

export const setCurrentUser = (payload: IUser | null): IAction<IUser | null> => ({
  type: SET_CURRENT_USER,
  payload,
});
