import { useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import SvgIcon from '../../../../../shared/svg-icon';
import Typography from '../../../../../shared/typography';
import { type INavigationList } from '../index';

const NavigationRowMobile = ({ icon, title, path }: INavigationList) => {
  const location = useLocation();

  const activeClassName = location.pathname.includes(path)
    ? 'xl:bg-[rgba(0,0,0,0.3)] border-[rgba(235,235,235,1)]'
    : 'xl:border-transparent';
  return (
    <Link
      to={path}
      className={`flex xl:flex-row flex-col px-[12px] py-[15px] mobile:py-0 gap-[14px] items-center rounded-[10px] xl:border ${activeClassName}`}
    >
      <SvgIcon
        icon={icon}
        className={`w-[20px] h-[20px] ${window.location.pathname.includes(path) ? 'stroke-blue-500' : 'stroke-white'}`}
      />
      <Typography
        variant="nav-link"
        className={`${window.location.pathname.includes(path) ? 'text-blue-500' : 'text-white'}`}
        tag="b"
      >
        {title}
      </Typography>
    </Link>
  );
};

export default NavigationRowMobile;
