import { type FC, memo } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { currentUserSelector } from '../../../store/selectors/auth';
import { companySelector } from '../../../store/selectors/company';
import { RolesEnum } from '../../../utils/enums';
import SvgIcon from '../svg-icon';

const sizes: Readonly<Record<string, string>> = {
  xsm: 'mobile:w-[90px] mobile:h-[30px] w-[150px] h-[50px]',
  sm: 'mobile:w-[90px] mobile:h-[30px] w-[180px] h-[60px]',
  lg: 'mobile:w-[90px] mobile:h-[30px] w-[300px] h-[100px]',
  admin: 'w-[150px] h-[50px] medium:w-[104px] medium:h-[40px]',
  invoice: 'mobile:w-[78px] mobile:h-[30px]',
} as const;

export type Size = keyof typeof sizes;

const colors: Readonly<Record<string, string>> = {
  white: 'fill-white',
  black: 'fill-black',
} as const;

export type Color = keyof typeof colors;

interface ILogo {
  size?: Size;
  color: Color;
  noLink?: boolean;
}

const Logo: FC<ILogo> = ({ size = 'sm', color, noLink = false }) => {
  const authUser = useSelector(currentUserSelector);
  const company = useSelector(companySelector);

  const getRedirectUrl = () => {
    const userRole = authUser?.roles[0];

    if (userRole) {
      if (userRole === RolesEnum.SuperAdmin) {
        return `/super-admin/`;
      } else if (userRole === RolesEnum.Admin) {
        return `/admin/`;
      } else {
        return `/employee/`;
      }
    }
    return '/';
  };
  return (
    <Link to={getRedirectUrl()} className={`${size !== 'admin' ? 'py-[15px]' : ''}`}>
      <SvgIcon icon="logo" className={`${sizes[size]} ${colors[color]}`} />
    </Link>
  );
};

export default memo(Logo);
