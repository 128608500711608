import { type IAction } from '../../@types';
import { SET_COMPANY_DATA, SET_PAYMENT_METHOD, SET_CUSTOMER } from '../types/invoice';

export interface ICoustumer {
  email: string;
  name: string;
  invoice?: ICoustumer;
  costumer?: ICoustumer;
}

export interface ICashPey {
  cash: string;
  invoice?: ICashPey;
  pay?: ICashPey;
}

export const setCompanyInfo = (payload: any): IAction<any> => ({
  type: SET_COMPANY_DATA,
  payload,
});

export const setCurrentCostumer = (payload: ICoustumer): IAction<ICoustumer> => ({
  type: SET_CUSTOMER,
  payload,
});

export const setPaymentMethod = (payload: ICashPey): IAction<ICashPey> => ({
  type: SET_PAYMENT_METHOD,
  payload,
});
