import axios from 'axios';

class InvoicesService {
  private readonly path: string;

  constructor() {
    this.path = 'https://api.mapbox.com/geocoding/v5/';
  }

  public async getCoords(body: any) {
    try {
      const requestPath = `${this.path}mapbox.places/${encodeURIComponent(
        `${body.street2 ? body.street2 + ' ' : ''}${body.street} ${body.location}`,
      )}.json`;

      const { data } = await axios.get(requestPath, {
        params: {
          access_token: process.env.REACT_APP_MAPBOX_TOKEN,
          language: 'en',
        },
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  }
}

export default new InvoicesService();
