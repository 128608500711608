import { memo, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useBack from '../../../../hooks/use-back';
import { currentUserSelector } from '../../../../store/selectors/auth';
import { companySelector } from '../../../../store/selectors/company';
import { RolesEnum } from '../../../../utils/enums';
import State from '../../../admin/invoice/invoice-flow/state';
import ConfirmationPopup from '../../../popups/confirmation-popup';
import Button from '../../../shared/input-sources/button';
import Typography from '../../../shared/typography';

const NewPageTitle = () => {
  const { goBack } = useBack();
  const navigate = useNavigate();
  const authUser = useSelector(currentUserSelector);
  const company = useSelector(companySelector);
  const [isOpenExitModal, setIsOpenExitModal] = useState(false);

  const currentURL = window.location.href;
  const [next, setNext] = useState(0);

  useEffect(() => {
    if (window.location.pathname.includes('create/company-data')) setNext(1);
    else if (window.location.pathname.includes('create/payment-processed')) setNext(5);
    else if (window.location.pathname.includes('create/pay')) setNext(3);
    else if (window.location.pathname.includes('create/add-invoice')) setNext(2);
    else if (window.location.pathname.includes('create/invoice-checkout')) setNext(4);
    else if (window.location.pathname.includes('create/credit-payment')) setNext(4);
    else setNext(0);
  }, [currentURL]);

  const getRedirectUrl = () => {
    const userRole = authUser?.roles[0];

    if (userRole) {
      if (userRole === RolesEnum.SuperAdmin && company) {
        return `/super-admin/dashboard/${company.id}/invoices`;
      } else if (userRole === RolesEnum.Admin) {
        return `/admin/invoices`;
      } else {
        return `/employee/dashboard/invoices`;
      }
    }

    return '/';
  };

  const backButton = () => {
    if (window.location.pathname.includes('create/customers')) {
      return false;
    } else if (window.location.pathname.includes('create/payment-processed')) {
      return false;
    } else if (window.location.pathname.includes('create/company-data')) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between w-full relative pb-[30px] ">
        {backButton() ? (
          <>
            <div className="flex mobile:hidden">
              <Button
                icon="chevron-left"
                iconClassName="stroke-black-1 w-[20px] h-[20px]"
                className="gap-[5px] z-10 "
                label="Back"
                size="back-admin"
                variant="admin-back"
                onClick={goBack}
              />
            </div>

            <Typography tag="h1" variant="sub-header" className="w-full text-center absolute left-0 z-0">
              Create Invoice
            </Typography>
            {!window.location.pathname.includes('create/payment-processed') && (
              <div className="w-fit z-10 mobile:hidden">
                <Button
                  className="gap-[5px] z-10 "
                  label="Exit"
                  size="back-admin"
                  variant="admin-back"
                  onClick={() => setIsOpenExitModal(true)}
                />
              </div>
            )}
          </>
        ) : (
          <div className="pb-[30px] h-[50px]">
            <Typography tag="h1" variant="sub-header" className="w-full text-center absolute left-0 z-0 pt-[12px]">
              Create Invoice
            </Typography>
            {!window.location.pathname.includes('create/payment-processed') && (
              <div className="w-fit z-10 mobile:hidden absolute right-0 h-fit">
                <Button
                  className="gap-[5px] z-10 "
                  label="Exit"
                  size="back-admin"
                  variant="admin-back"
                  onClick={() => setIsOpenExitModal(true)}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <State next={next} />
      {isOpenExitModal ? (
        <ConfirmationPopup
          onClose={() => setIsOpenExitModal(false)}
          onSubmit={() => navigate(getRedirectUrl())}
          content={`Your Invoice will be saved as Draft. <br>Are you sure you want to exit now? `}
          noButtonLabel="No"
          yesButtonLabel="Yes, exit"
          invoicesInfo
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(NewPageTitle);
