import { memo } from 'react';

import { useLocation } from 'react-router-dom';

import GoBack from '../../shared/go-back';
import Logo from '../../shared/logo';
import NavigationBar from '../../shared/navigation-bar';

const DefaultHeader = () => {
  const location = useLocation();
  const currentUrl = window.location.href;

  return (
    <header className="h-[90px] relative bg-black-1 text-white w-full flex justify-center">
      <div className="!w-[1470px] flex items-center mobile:justify-center justify-between shadow-lg">
        {location.pathname !== '/' &&
          !location.pathname.includes('payment') &&
          !location.pathname.includes('authorization-accepted') &&
          !location.pathname.includes('work-order-reference') && <GoBack />}
        <Logo color="white" />
        <NavigationBar currentUrl={currentUrl} />
      </div>
    </header>
  );
};

export default memo(DefaultHeader);
