import { memo, useEffect, useRef, useState } from 'react';

import useUser from '../../../hooks/use-user';
import Button from '../input-sources/button';
import HeaderSelect from '../input-sources/header-select';

const NavigationBar = ({ currentUrl }: any) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const user = useUser();
  const settingsWindowRef = useRef<any>(null);
  const settingsButtonRef = useRef<any>(null);

  useEffect(() => {
    setIsOpenModal(false);
  }, [currentUrl]);

  useEffect(() => {
    const handleOutSideClick = (event: MouseEvent) => {
      if (settingsWindowRef.current && !settingsWindowRef.current.contains(event.target as Element)) {
        if (isOpenModal && settingsButtonRef.current && !settingsButtonRef.current.contains(event.target as Element))
          setIsOpenModal(false);
      }
    };

    if (isOpenModal) {
      document.addEventListener('mousedown', handleOutSideClick);
    } else {
      document.removeEventListener('mousedown', handleOutSideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutSideClick);
    };
  }, [isOpenModal]);

  return (
    <div className="mobile:hidden flex flex-row gap-[50px] items-center">
      {!user ? (
        <></>
      ) : (
        <>
          <div
            className={`flex justify-center items-center w-[45px] h-[45px] rounded-full ${
              isOpenModal && 'bg-gray-500'
            } hover:bg-gray-500`}
            ref={settingsButtonRef}
          >
            <Button
              icon="menu"
              iconClassName="stroke-white w-[24px] h-[24px]"
              variant="text"
              size="icon"
              onClick={() => setIsOpenModal(!isOpenModal)}
            />
          </div>
          {isOpenModal && (
            <div
              className="absolute z-50 w-[200px] h-[95px] bg-black-2 mt-[185px] ml-[-154px] rounded-b-[10px]"
              ref={settingsWindowRef}
            >
              <HeaderSelect />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(NavigationBar);
