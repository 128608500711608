import { Outlet } from 'react-router-dom';

import Container from '../components/layout/container';
import Footer from '../components/layout/footer';
import DefaultHeader from '../components/layout/header/default';
import ChatBot from '../pages/chat-bot';

const DefaultLayout = () => {
  return (
    <Container>
      <DefaultHeader />
      <Outlet />
      <ChatBot />
      <Footer />
    </Container>
  );
};

export default DefaultLayout;
