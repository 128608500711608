import Typography from '../../../../shared/typography';

interface IState {
  next: number;
}

const State = ({ next }: IState) => {
  return (
    <div className="w-full flex items-center justify-center">
      <div className="mobile:w-full flex justify-between">
        <div className="flex flex-col items-center w-[160px] mobile:w-[68px]">
          <div
            className={`rounded-full w-[60px] h-[60px] medium:w-[45px] medium:h-[45px] mobile:w-[35px] mobile:h-[35px] ${
              next >= 2 ? 'bg-blue-500' : 'bg-white-2'
            } flex justify-center items-center border border-blue-500`}
          >
            <Typography tag="p" variant="sub-header" className={`${next >= 2 ? 'text-white' : 'text-blue-500'}`}>
              1
            </Typography>
          </div>
          <Typography
            tag="p"
            variant="body-1"
            className={`${next >= 0 && 'text-blue-500'} medium:w-[100px] mobile:!w-[80px] text-center`}
          >
            Customer Info
          </Typography>
        </div>
        <div className="flex items-center justify-center medium:w-[50px] w-[160px] mobile:!w-full mobile:!mt-[-50px] mt-[-30px] ml-[-39px] mobile:ml-0 ">
          <hr className={`w-[50%] h-[2px] ${next >= 1 ? 'bg-blue-500' : 'bg-white-2'} rounded`} />
          {next == 1 && <div className="bg-blue-500 h-[7px] w-[7px] rounded-full"></div>}
          <hr className={`w-[50%] h-[2px] ${next >= 2 ? 'bg-blue-500' : 'bg-white-2'} rounded`} />
        </div>
        <div className="flex flex-col items-center w-[160px] mobile:w-[60px] ml-[-30px] mobile:ml-0">
          <div
            className={`rounded-full w-[60px] h-[60px] medium:w-[45px] medium:h-[45px] mobile:w-[35px] mobile:h-[35px] ${
              next >= 2 && 'border-blue-500'
            } ${next > 2 ? 'bg-blue-500' : 'bg-white-2'} flex justify-center items-center border`}
          >
            <Typography
              tag="p"
              variant="sub-header"
              className={`${next == 2 ? 'text-blue-500' : 'text-gray-500'} ${next > 2 && 'text-white'}`}
            >
              2
            </Typography>
          </div>
          <Typography
            tag="p"
            variant="body-1"
            className={`${next >= 2 ? 'text-blue-500' : 'text-gray-500'} medium:w-[112px] mobile:!w-[80px] text-center`}
          >
            Charges
          </Typography>
        </div>
        <div className="flex items-center justify-center medium:w-[50px] w-[160px] mobile:!w-full mobile:!mt-[-50px] mt-[-30px] ml-[-39px] mobile:ml-0 ">
          <hr className={`w-[100%] h-[2px] ${next >= 3 ? 'bg-blue-500' : 'bg-white-2'} rounded`} />
        </div>
        <div className="flex flex-col items-center w-[160px] mobile:w-[60px] ml-[-30px] mobile:ml-0">
          <div
            className={`rounded-full w-[60px] h-[60px] medium:w-[45px] medium:h-[45px] mobile:w-[35px] mobile:h-[35px] ${
              next >= 3 && 'border-blue-500'
            } ${next >= 4 ? 'bg-blue-500' : 'bg-white-2'} flex justify-center items-center border`}
          >
            <Typography
              tag="p"
              variant="sub-header"
              className={`${next == 3 ? 'text-blue-500' : 'text-gray-500'} ${next > 3 && 'text-white'}`}
            >
              3
            </Typography>
          </div>
          <Typography tag="p" variant="body-1" className={`${next >= 3 ? 'text-blue-500' : 'text-gray-500'}`}>
            Payment Method
          </Typography>
        </div>
        <div className="flex items-center justify-center medium:w-[50px] w-[160px] mobile:!w-full mobile:!mt-[-50px] mt-[-30px] ml-[-39px] mobile:ml-0 ">
          <hr className={`w-[100%] h-[2px] ${next >= 4 ? 'bg-blue-500' : 'bg-white-2'} rounded`} />
        </div>
        <div className="flex flex-col items-center w-[160px] mobile:w-[60px] ml-[-30px] mobile:ml-0">
          <div
            className={`rounded-full w-[60px] h-[60px] medium:w-[45px] medium:h-[45px] mobile:w-[35px] mobile:h-[35px] ${
              next >= 4 && 'border-blue-500'
            } ${next > 4 ? 'bg-blue-500' : 'bg-white-2'} flex justify-center items-center border`}
          >
            <Typography
              tag="p"
              variant="sub-header"
              className={`${next == 4 ? 'text-blue-500' : 'text-gray-500'} ${next > 4 && 'text-white'}`}
            >
              4
            </Typography>
          </div>
          <Typography tag="p" variant="body-1" className={`${next >= 4 ? 'text-blue-500' : 'text-gray-500'}`}>
            Checkout
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default State;
