import Typography from '../../../../components/shared/typography';

const YourMassage = ({ message }: any) => {
  return (
    <div className="w-full flex flex-col items-end">
      <div className="flex justify-center gap-[10px]">
        <div className="flex items-center">
          <Typography tag="span" variant="data" className="text-gray-500 break-words">
            {message?.date}
          </Typography>
        </div>
        <div className="bg-blue-500 px-[15px] py-[10px] rounded-[15px] max-w-[300px]">
          <Typography tag="span" variant="chat-message" className="text-white break-words">
            {message?.content}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default YourMassage;
