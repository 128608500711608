import Button from '../../shared/input-sources/button';
import Typography from '../../shared/typography';
import PopupWrapper from '../popup-wrapper';

const ConfirmationPopup = ({
  content,
  onClose,
  onSubmit,
  serviceName,
  invoicesInfo,
  textClassName,
  noButtonLabel = 'No, exit',
  yesButtonLabel,
  className,
  attachedCompanies,
  please,
  action,
}: any) => {
  return (
    <PopupWrapper>
      <div className={`${className ? className : 'w-fit'} flex flex-col gap-[20px]`}>
        <Typography variant="body-3" tag="div" className={`${textClassName} flex justify-center`}>
          {content}
        </Typography>
        <div className="flex flex-col gap-[20px] w-full">
          {serviceName && attachedCompanies && (
            <div className="pt-[20px]">
              <div className="flex">
                <Typography variant="body-3" tag="h1" className="">
                  <span className="text-slate-600 ">Service Name: {''}</span>
                  {serviceName}
                </Typography>
              </div>
              <div className="">
                <div className="flex h-fit w-fit">
                  <Typography variant="body-3" tag="h1" className="">
                    <span className="text-slate-600 ">Attached Companies: {''}</span>
                    {attachedCompanies}
                  </Typography>
                </div>
                <div className="flex h-fit w-fit">
                  <Typography variant="body-3" tag="div" className={`${textClassName}`}></Typography>
                </div>
              </div>
            </div>
          )}
          {please && (
            <Typography variant="body-3" tag="div" className="text-slate-600 ">
              {please}
            </Typography>
          )}
          {action && (
            <Typography variant="body-3" tag="div" className="text-red-500 ">
              {action}
            </Typography>
          )}
          <Button
            label={noButtonLabel}
            variant={invoicesInfo ? 'admin-secondary' : 'secondary'}
            size="back-admin"
            onClick={onClose}
            className={`${invoicesInfo ? 'text-gray-500' : 'text-white'} w-full h-[60px]`}
          />
          <Button
            label={yesButtonLabel ? yesButtonLabel : invoicesInfo ? 'Yes, I’m sure' : 'Yes, delete it'}
            variant={invoicesInfo ? 'secondary' : 'admin-secondary'}
            size="back-admin"
            onClick={onSubmit}
            className={`${invoicesInfo ? 'text-white' : 'text-red-500'} w-full h-[60px]`}
          />
        </div>
      </div>
    </PopupWrapper>
  );
};

export default ConfirmationPopup;
