import { Link, useLocation } from 'react-router-dom';

import SvgIcon from '../../../../shared/svg-icon';
import Typography from '../../../../shared/typography';
import { type INavigationList } from '../index';

const NavigationRow = ({ icon, title, path, onClick }: INavigationList) => {
  const location = useLocation();

  const activeClassName = location.pathname.includes(path)
    ? 'bg-[rgba(0,0,0,0.3)] border-[rgba(235,235,235,1)]'
    : 'border-transparent hover:bg-[#1e1e1e]';

  return (
    <Link
      to={path}
      onClick={() => {
        onClick ? onClick() : '';
      }}
      className={`flex flex-row px-[19px] py-[15px] gap-[14px] items-center rounded-[10px] border ${activeClassName}`}
    >
      <SvgIcon icon={icon} className="w-[20px] h-[20px] stroke-white" />
      <Typography variant="nav-link" className="text-white" tag="b">
        {title}
      </Typography>
    </Link>
  );
};

export default NavigationRow;
