import { type Nullable } from '../../../@types';
import axiosClient from '../index';

export interface ISearchQuery {
  location: Nullable<string>;
  type: Nullable<string>;
}

class ChatService {
  private readonly path: string;

  constructor() {
    this.path = '/getChatHistory';
  }

  public async getChatService(emailOrPhone: string) {
    try {
      const { data } = await axiosClient.get(`${this.path}/?emailOrPhone=${emailOrPhone}`);
      return data;
    } catch (e) {
      console.error(e);
    }
  }
  public async deleteChat(emailOrPhone: string) {
    try {
      const { data } = await axiosClient.delete(`${this.path}/?emailOrPhone=${emailOrPhone}`);
      return data;
    } catch (e) {
      console.error(e);
    }
  }
}

export default new ChatService();
