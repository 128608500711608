import SvgIcon from '../../../../components/shared/svg-icon';
import Typography from '../../../../components/shared/typography';

const MyMassage = ({ message }: any) => {
  const dateObject = new Date(message?.date);

  const minutes = ('0' + dateObject.getMinutes()).slice(-2);
  const hours = ('0' + dateObject.getHours()).slice(-2);
  const formattedDate = hours + ':' + minutes;

  return (
    <div className="pb-[10px]">
      <div className="flex h-full gap-[10px]">
        <div className="flex items-center">
          <div className="w-[40px] h-[40px] bg-white flex justify-center items-center rounded-full border border-slate-600 border-opacity-30">
            <SvgIcon icon="user" className="stroke-blue-500 w-[20px] h-[24px]" />
          </div>
        </div>
        <div className="flex h-fit justify-start">
          <div className="bg-white px-[15px] py-[10px] rounded-[15px] max-w-[300px] border border-slate-600 border-opacity-30">
            <Typography tag="span" variant="chat-message" className="text-gray-500 break-words">
              {message.content}
            </Typography>
          </div>
          <div className="flex items-center">
            <Typography tag="span" variant="data" className="text-gray-500 break-words">
              {formattedDate}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyMassage;
