import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '../../../../components/shared/input-sources/button';
import Input from '../../../../components/shared/input-sources/input';
import SvgIcon from '../../../../components/shared/svg-icon';
import Typography from '../../../../components/shared/typography';
import { emailRegex, phoneRegex } from '../../../../utils/helpers';

const schema = yup.object({
  companyName: yup.string(),
  phoneOrEmail: yup
    .string()
    .required('phone or email is required')
    .test('validate', 'invalid format', (value: any) => {
      return emailRegex.test(value) || phoneRegex.test(value);
    }),
});

const MailOrPhoneMessage = ({ emailSubmit, socket, confirm }: any) => {
  const [value, setValue] = useState<string>('');
  const [errorMessage, setErrorMassage] = useState<any>(null);
  const [errorMessageInput, setErrorMessageInput] = useState<any>(null);

  useEffect(() => {
    socket.on('confirm', (data: string) => {
      setErrorMassage(data);
    });
  }, []);

  useEffect(() => {
    if (value) {
      setErrorMessageInput(null);
    }
  }, [value]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: '',
      phoneOrEmail: '',
    },
  });

  return (
    <>
      <div className="w-full h-[70vh] relative flex flex-col justify-center items-center">
        <div className="flex justify-center items-center h-full gap-[10px]">
          <div className="flex w-full px-[20px] mobile:px-[10px] pt-[30px]">
            <form
              className="bg-white h-[453px] medium:h-[400px] px-[20px] mobile:px-[15px] rounded-[15px] w-full flex flex-col gap-[20px] border border-slate-600 border-opacity-30"
              onSubmit={handleSubmit(emailSubmit)}
            >
              <div className="flex justify-center items-center">
                <div className="w-[70px] h-[70px] bg-blue-500 flex justify-center items-center rounded-full mt-[-35px] z-10">
                  <SvgIcon icon="logo" className="fill-white w-[60px] h-[24px]" />
                </div>
              </div>
              <div>
                <div className="flex justify-center">
                  <Typography tag="span" variant="chat-message" className="text-gray-500 break-words">
                    Hello there! 👋🏻
                  </Typography>
                </div>
                <Typography tag="span" variant="chat-message" className="text-gray-500 break-words">
                  Feel free to ask me anything, but to get us started, please provide the following information below.
                </Typography>
              </div>
              {!confirm ? (
                <>
                  <div className="flex flex-col gap-[29px] medium:gap-[20px] pb-[29px] medium:pb-[19px] pt-[10px] medium:pt-[0]">
                    <Controller
                      render={({ field }) => (
                        <Input
                          placeholder="Email address or Phone number"
                          type="text"
                          error={!!errors?.phoneOrEmail?.message}
                          helperText={errors?.phoneOrEmail?.message}
                          inputClassName="w-full h-[50px] focus:!border-none focus-visible:border-none outline-none rounded-[10px] p-[10px] bg-white-2"
                          iconColorClass="stroke-grey-500 rotate-[41deg]"
                          {...field}
                        />
                      )}
                      name="phoneOrEmail"
                      control={control}
                    />
                    <Controller
                      render={({ field }) => (
                        <Input
                          placeholder="Company Name"
                          type="text"
                          error={!!errors?.companyName?.message}
                          helperText={errors?.companyName?.message}
                          inputClassName="w-full h-[50px] focus:!border-none focus-visible:border-none outline-none rounded-[10px] p-[10px] bg-white-2"
                          iconColorClass="stroke-grey-500 rotate-[41deg]"
                          {...field}
                        />
                      )}
                      name="companyName"
                      control={control}
                    />
                  </div>
                  <Button size="send-email" label="Send Verification Code" />
                </>
              ) : (
                <>
                  <div className="flex flex-col gap-[29px] pb-[19px] h-[119px]">
                    <Input
                      placeholder="Enter verification code"
                      type="text"
                      error={errorMessage === 'Verify failed' || errorMessageInput}
                      onChange={(e: any) => setValue(e.target.value)}
                      inputClassName="w-full h-[40px] focus:!border-none focus-visible:border-none outline-none rounded-[10px] p-[10px] bg-white-2 h-[50px]"
                      iconColorClass="stroke-grey-500 rotate-[41deg]"
                    />
                    {errorMessage === 'Verify failed' && !errorMessageInput && (
                      <Typography tag="span" variant="error-message" className="text-red-500 w-fit absolute mt-[55px]">
                        Invalid Code
                      </Typography>
                    )}
                    {errorMessageInput && (
                      <Typography tag="span" variant="error-message" className="text-red-500 w-fit absolute mt-[55px]">
                        confirmation code is required
                      </Typography>
                    )}
                  </div>
                  <Button
                    label="Start Chat"
                    size="send-email"
                    onClick={() => (value ? emailSubmit(value) : setErrorMessageInput('confirm is required'))}
                  />
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailOrPhoneMessage;
