import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import userService from '../../core/api/user-service';
import { setCurrentUser } from '../../store/actions/auth';
import { currentUserSelector } from '../../store/selectors/auth';

function useUser() {
  const dispatch = useDispatch();

  const user = useSelector(currentUserSelector);

  useEffect(() => {
    const token = sessionStorage.getItem('access_token') || localStorage.getItem('access_token');

    if (!user && token) {
      (async () => {
        const data = await userService.getCurrentUser();

        dispatch(setCurrentUser(data));
      })();
    }
  }, []);

  return user;
}

export default useUser;
