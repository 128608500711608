import { type JSX } from 'react';

import { Navigate } from 'react-router-dom';

import useUser from '../hooks/use-user';

interface IProtectedRoute {
  children: JSX.Element;
  userRole?: string;
}

const ProtectedRoute = ({ children, userRole }: IProtectedRoute) => {
  const user: any = useUser();

  if (!user) return <Navigate to="/" replace />;

  if (userRole && !user.roles.includes(userRole)) return <Navigate to="/not-found" replace />;

  return children;
};

export default ProtectedRoute;
